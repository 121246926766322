import type { Product } from './types';

export const SEARCH_PRODUCT_SUGGESTIONS = 'PRODUCT/SEARCH/SUGGESTION' as const;

//3.8.Integration with Code d�or � Product grouping page
export const searchProductsSuggestions = (keywords: string, searchType = 'parentsonly') => ({
    type: SEARCH_PRODUCT_SUGGESTIONS,
    payload: { keywords, searchType },
});

export const SEARCH_PRODUCT_SUGGESTIONS_COMPLETED = 'PRODUCT/SEARCH/SUGGESTION/COMPLETED' as const;
export const productsSearchSuggestionsCompleted = (products: Product[]) => ({
  type: SEARCH_PRODUCT_SUGGESTIONS_COMPLETED,
  payload: products,
});

export const SEARCH_PRODUCT_SUGGESTIONS_CLEARED = 'PRODUCT/SEARCH/SUGGESTION/CLEARED' as const;
export const clearProductsSuggestions = () => ({
  type: SEARCH_PRODUCT_SUGGESTIONS_CLEARED,
});

export type SearchSuggestionsAction = ReturnType<
  | typeof searchProductsSuggestions
  | typeof productsSearchSuggestionsCompleted
  | typeof clearProductsSuggestions
>;
